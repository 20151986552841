import '../../style/main/main-footer.scss'
import { AppStoreWhite } from '../../svg/AppStore'
import { GooglePlayWhite } from '../../svg/GooglePlay'
import { ArrowLeft, ArrowRight } from '../../svg/Arrow'
import { useTranslation } from 'react-i18next';

const PhoneFooter = () => {
    const { t } = useTranslation();

    return (
        <div className='main__footer-content'>
            <h4 className='main--uptitle' style={{ opacity: 1 }}>
                {t('main.t19')}
            </h4>

            {/* Кнопка App Store */}
            <div className='main__footer-contentt-market'>
                <h3>{t('main.t20')}</h3>
                <p>{t('main.t21')}</p>
                <a 
                    href="https://apps.apple.com/ua/app/populi-your-people-your-events/id1672278257" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className='main__footer-content-lable main__footer-button'
                >
                    <AppStoreWhite />
                </a>
                <div className='main__footer-content-left'>
                    <ArrowLeft />
                </div>
            </div>

            {/* Кнопка Google Play */}
            <div className='main__footer-contentt-market'>
                <h3>{t('main.t20')}</h3>
                <p>{t('main.t22')}</p>
                <a 
                    href="https://play.google.com/store/apps/details?id=com.wgroup.populi" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className='main__footer-content-lable main__footer-button'
                >
                    <GooglePlayWhite />
                </a>
                <div className='main__footer-content-right'>
                    <ArrowRight />
                </div>
            </div>
        </div>
    );
}

export default PhoneFooter;
