import { useState, useRef, useEffect } from 'react'
import '../../style/footer.scss'
import FooterList from './FooterList'
import { useTranslation } from 'react-i18next'

const Footer = () => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const footerModal = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (footerModal.current && !footerModal.current.contains(event.target as Node)) {
                setOpen(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    return (
        <footer className='footer' id="FOOTER">
            <div className='container__wraper'>
                <div className='footer__body'>
                    <div className='footer__body-list'>
                        <FooterList />
                    </div>
                    
                    {/* Кнопка для открытия модального меню */}
                    <button 
                        className='footer__button' 
                        onClick={() => setOpen((prev) => !prev)}
                        aria-label={t('footer.menu')}
                    >
                        {open ? (
                            <div className='footer__listmodal' ref={footerModal}>
                                <FooterList />
                            </div>
                        ) : (
                            <>{t('footer.open_menu')}</>
                        )}
                    </button>

                    <div className='footer__app'>
                        © {new Date().getFullYear()} Populi Application. {t('footer.rights')}
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
