import React from 'react'
import { AppStoreWhite } from '../../svg/AppStore'
import { GooglePlayWhite } from '../../svg/GooglePlay'

const MainTPFooter = () => {
    return (
        <div className='container__wraper'>
            <h4 className='main--uptitle'>
                The best place for events
            </h4>
            <div className='main__tp_footer' id='MainFooter'>
                <div className='main__tp_footer-body'>
                    <h5>Download</h5>
                    <a 
                        href="https://apps.apple.com/ua/app/populi-your-people-your-events/id1672278257" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="main__tp_footer-button"
                    >
                        <AppStoreWhite />
                    </a>
                    <a 
                        href="https://play.google.com/store/apps/details?id=com.wgroup.populi" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="main__tp_footer-button"
                    >
                        <GooglePlayWhite />
                    </a>
                </div>
                <div className='main__tp_footer-phone' />
            </div>
        </div>
    )
}

export default MainTPFooter
