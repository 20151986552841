import { useEffect, useRef, useState, Suspense, lazy } from 'react'
import Loader from '../loader/Loader'
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import "../../style/main/main.scss"
import "../../style/main-tp/main-tp.scss"
import MainTP from '../main-tablet-phone/MainTP'
import { I18nextProvider, useTranslation } from 'react-i18next'
import i18n from '../../i18n/i18n'
import Main from '../main/Main'
import { LANGUAGE } from '../../types/enum'
import { LanguageType } from '../../types/types'

const About = lazy(() => import('../about/About'))
const Contacts = lazy(() => import('../contacts/Contacts'))
const Agreement = lazy(() => import('../agreement/Agreement'))
const NotFound = lazy(() => import('../not-found/NotFound'))

const UpdateMetaTags = () => {
    const { t } = useTranslation()
    const location = useLocation()

    useEffect(() => {
        let title = t('meta.main_title')
        let description = t('meta.main_description')

        if (location.pathname === "/about") {
            title = t('meta.about_title')
            description = t('meta.about_description')
        } else if (location.pathname === "/contacts") {
            title = t('meta.contacts_title')
            description = t('meta.contacts_description')
        } else if (location.pathname === "/agreement") {
            title = t('meta.agreement_title')
            description = t('meta.agreement_description')
        } else if (location.pathname === "/policy") {
            title = t('meta.policy_title')
            description = t('meta.policy_description')
        }

        document.title = title
        document.querySelector('meta[name="description"]')?.setAttribute("content", description)
    }, [location, t])

    return null
}

const App = () => {
    const [language, setLanguage] = useState<LanguageType>(LANGUAGE.EN)
    const [isDesktop, setIsDesktop] = useState(false)
    const appRef = useRef<any>(null)
    const [isLoad, setIsLoad] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setIsLoad(false)
        }, 3500)
    }, [])

    useEffect(() => {
        const userAgent = window.navigator.userAgent
        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)
        const isTablet = /Tablet|iPad/i.test(userAgent)

        setIsDesktop(!isMobile && !isTablet)
    }, [])

    useEffect(() => {
        appRef.current = document.querySelector('.app')

        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 0)

        // ❌ УДАЛЕНО: Принудительный редирект, который ломал навигацию
        setTimeout(() => {
            appRef!.current!.style!.background = 'transparent'
        }, 3000)
    }, [])

    return (
        <div className='app' ref={appRef}>
            <Suspense fallback={<Loader />}>
                <I18nextProvider i18n={i18n}>
                    <Router>
                        <UpdateMetaTags />
                        {isLoad ? <Loader /> : (
                            <>
                                <Header setLanguage={(s) => setLanguage(s)} />
                                <div className='app__content'>
                                    <Routes>
                                        <Route path="/" element={isDesktop ? <Main /> : <MainTP />} />
                                        <Route path="/about" element={<About />} />
                                        <Route path="/contacts" element={<Contacts />} />
                                        <Route path="/agreement" element={<Agreement language={language} title="agreement" />} />
                                        <Route path="/policy" element={<Agreement language={language} title="policy" />} />
                                        <Route path="*" element={<NotFound />} />
                                    </Routes>
                                </div>
                                <Footer />
                            </>
                        )}
                    </Router>
                </I18nextProvider>
            </Suspense>
        </div>
    )
}

export default App
